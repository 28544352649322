import React from "react"
import styled from "styled-components"
import tw from "tailwind.macro"
import BackgroundImage from "gatsby-background-image"

const SubBannerWrap = styled.section`
  ${tw`overflow-hidden`}

  .sub-banner-inner {
    ${tw`flex items-center justify-center py-10 px-18 md:px-40 bg-primary bg-no-repeat bg-cover bg-center`}
    min-height: 300px;
    height: 300px;

    :after,
    :before {
      filter: blur(5px) contrast(70%);
      width: calc(100% + 25px) !important;
      height: calc(100% + 25px) !important;
      margin: -25px;
    }
  }

  .inner-wrap {
    ${tw`max-w-md text-center text-white`}
  }

  h1 {
    ${tw`font-bold font-fb text-5xl`}
    line-height: 64px;
  }

  h5 {
    ${tw`font-medium text-base leading-8 uppercase`}
    letter-spacing: 1.6px;
  }
`

const SubBanner = ({ mainTitle, subTitle, image }) => {
  return (
    <SubBannerWrap>
      {image ? (
        <BackgroundImage tag="div" className="sub-banner-inner" fluid={image}>
          <div className="inner-wrap">
            {mainTitle && <h1>{mainTitle}</h1>}
            {subTitle && <h5>{subTitle}</h5>}
          </div>
        </BackgroundImage>
      ) : (
        <div className="sub-banner-inner">
          <div className="inner-wrap">
            {mainTitle && <h1>{mainTitle}</h1>}
            {subTitle && <h5>{subTitle}</h5>}
          </div>
        </div>
      )}
    </SubBannerWrap>
  )
}

export default SubBanner
