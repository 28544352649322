import React from "react"
import styled from "styled-components"
import Link from "../Link"
import tw from "tailwind.macro"

const CTASection = styled.section`
  .cta-inner {
    ${tw`bg-primary`}
    padding: 95px 35px 100px;

    @media (min-width: 768px) {
      padding: 145px 35px 150px;
    }

    @media (min-width: 1120px) {
      padding: 195px 35px 200px;
    }
  }

  .content-width-wrap {
    ${tw`text-center max-w-lg mx-auto`}
  }

  h3 {
    ${tw`text-white font-fb mb-16 md:mb-24 lg:mb-28 px-10`}
    font-size: 28px;
    line-height: 44px;

    @media (min-width: 768px) {
      font-size: 32px;
    }
  }

  .cta-btn-wrap {
    ${tw`md:flex max-w-md mx-auto`}
    max-width: 445px;
  }

  .btn-secondary {
    ${tw`font-fb`}
    box-shadow: 5px 5px 10px #00000029;

    :hover {
      ${tw`border-white`}
    }
  }
`

const CTA = props => (
  <CTASection className="section-gap-sm xl:section-gap-xl">
    <div className="cta-inner">
      <div className="content-width-wrap">
        <h3>{props.text}</h3>
        <div className="cta-btn-wrap">
          <Link
            className="btn-secondary w-full mb-12 md:mb-0 md:mr-10 md:btn-sm"
            to={props.buttonOne.url}
            target={props.buttonOne.target}
          >
            {props.buttonOne.title}
          </Link>
          <Link
            className="btn-primary-invert w-full lg:py-8 md:btn-sm"
            href={props.buttonTwo.url}
            target={props.buttonTwo.target}
          >
            {props.buttonTwo.title}
          </Link>
        </div>
      </div>
    </div>
  </CTASection>
)

export default CTA
