import React from "react"
import { PropTypes } from "prop-types"
import classNames from "classnames"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"
import tw from "tailwind.macro"
import Flickity from "react-flickity-component"
import SpeechMarkIcon from "../../assets/speechmark_icon.svg"

const TestimonialsSection = styled.section`
    ${tw`max-w-xl mx-auto px-18 md:px-40 lg:px-20`}

    .testimonial-item {
        ${tw`w-full opacity-0 lg:flex`}
        transition: opacity 300ms ease-in;

        &.is-selected {
            ${tw`opacity-100`}
        }
    }

    .person-image-outer-wrap {
        ${tw`pb-80 md:pb-54 lg:w-2/4 xl:pb-0`}
    }
    
    .one-slide  {
      .person-image-outer-wrap {
        ${tw`pb-40 md:pb-40 xl:pb-0`}
      }
    }

    .person-image-wrap {
        ${tw`mx-auto`}
        width: 252px;
        height: 252px;

        @media (min-width: 1280px) {
          width: 325px;
          height: 325px;
        }
    }

    .person-image {
      ${tw`h-full bg-center bg-no-repeat bg-cover`}
      
      &:after,
      &:before {
        border-radius: 163px;
      }
    }

    .testimonial-details {
        ${tw`relative pt-22 sm:px-32 lg:w-2/4 md:pt-0`}

        ::before {
            ${tw`absolute left-0 top-0`}
            content: url(${SpeechMarkIcon});

            @media (min-width: 640px) {
                top: 15px;
            }
        }
    }

    p {
        ${tw`text-xl xl:text-2xl xl:font-medium`}
        line-height: 44px;
    }

    .reviewer-details {
        ${tw`mt-22`}

        h5 {
        ${tw`text-xl font-fb xl:text-2xl`}
        line-height: 44px;
        }
    }

    .carousel {
    ${tw`relative overflow-hidden outline-none`}
    }

    .flickity-page-dots {
        top: 300px;
        ${tw`absolute list-none w-full flex items-center justify-center p-0 lg:w-2/4`}

        @media (min-width: 1280px) {
          top: 390px;
        }

        li:only-child{
          display: none;
        }
    }

    .flickity-page-dots .dot {
        width: 10px;
        height: 10px;
        margin: 0 15px;
        border-radius: 50%;
        ${tw`inline-block bg-grey-light cursor-pointer`}
    }

    .flickity-page-dots .dot.is-selected {
        ${tw`bg-primary`}
        width: 15px;
        height: 15px;
    }
`

const flickityOptions = {
  initialIndex: 0,
  wrapAround: true,
  cellSelector: ".testimonial-item",
  accessibility: true,
  prevNextButtons: false,
  pageDots: true,
  draggable: ">1",
  fade: true,
  imagesLoaded: true,
}

const Testimonials = ({ data }) => {
  return (
    <TestimonialsSection className="section-gap-sm xl:section-gap-xl">
      <Flickity
        className={classNames("carousel", {
          "one-slide": data.length === 1,
        })}
        options={flickityOptions}
      >
        {data.map((item, index) => (
          <div key={index} className="testimonial-item">
            <div className="person-image-outer-wrap">
              <div className="person-image-wrap">
                <BackgroundImage
                  tag="div"
                  className="person-image"
                  fluid={item.testimonialImage.localFile.childImageSharp.fluid}
                ></BackgroundImage>
              </div>
            </div>
            <div className="testimonial-details">
              <p>{item.testimonialContent}</p>
              <div className="reviewer-details">
                <h5>{item.testimonialAuthorName}</h5>
                <h5>{item.testimonialAuthorCompany}</h5>
              </div>
            </div>
          </div>
        ))}
      </Flickity>
    </TestimonialsSection>
  )
}

Testimonials.propTypes = {
  show: PropTypes.bool,
  personImage: PropTypes.string,
  content: PropTypes.string,
  authorName: PropTypes.string,
  authorCompany: PropTypes.string,
}

export default Testimonials
