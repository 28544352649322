import React from "react"
import SEO from "components/seo"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SubBanner from "../components/SubBanner"
import Form from "../components/section-form"
import Testimonials from "../components/Testimonials"
import CTA from "../components/CTA"

export const query = graphql`
  query Contactus {
    page: allWpPage(filter: { slug: { eq: "contact-us" } }) {
      edges {
        node {
          acfSubPageBanner {
            sloganText: subPageBannerSloganText
            sloganTitle: subPageBannerSloganTitle
            subPageBannerImage {
              localFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
          acfCta {
            ctaShow
            ctaText
            ctaLink1 {
              target
              title
              url
            }
            ctaLink2 {
              target
              title
              url
            }
          }
          acfTestimonials {
            testimonialShow
            testimonialCard {
              testimonialAuthorCompany
              testimonialAuthorName
              testimonialContent
              testimonialImage {
                localFile {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 325) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ...SEO
        }
      }
    }
  }
`

const ContactUsPage = ({ location, data }) => {
  const {
    seo,
    acfSubPageBanner,
    acfCta,
    acfTestimonials,
  } = data.page.edges[0].node

  return (
    <Layout path={location.pathname}>
      <SEO data={seo} />
      <SubBanner
        mainTitle={acfSubPageBanner.sloganTitle}
        subTitle={acfSubPageBanner.sloganText}
        image={acfSubPageBanner?.subPageBannerImage?.localFile?.childImageSharp?.fluid}
      />
      <Form className="pt-none-important" />

      {acfTestimonials.testimonialShow && (
        <Testimonials data={acfTestimonials.testimonialCard} />
      )}

      {acfCta.ctaShow && (
        <CTA
          text={acfCta.ctaText}
          buttonOne={acfCta.ctaLink1}
          buttonTwo={acfCta.ctaLink2}
        />
      )}
    </Layout>
  )
}

export default ContactUsPage
